
.ceo-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(35, 110, 201);
    padding: 10%;
    flex-wrap: wrap;
}

.ceo-section h1{
    width: 70%;
    font-family: 'Barlow-Regular';
    font-size: 30px;
    text-align: center;
    align-self: center;    
    color:azure;
}

.ceo-section p{
    width: 70%;
    font-weight: bold;
    font-family: 'Barlow-thin';
    font-size: 18px;
    align-self: center;
    text-align: center;
    color:azure;
}

.ceo-section div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ceo {
    padding-bottom: 20px;
}


.ceo-section img {
    width: 30%;
}
.aboutus-container{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100vh;
}

.about-us-white-sec{
    background-color: rgb(255, 255, 255);
    width: 100%;
}

.about-us-first-sec-container {
    position: relative;
    display: flex;
    justify-content: center;
    /* transform: translate(-50%, -50%); */
}

.about-us-first-sec-container img{
   width: 50%;
   animation: fadeIn 5s; 
}


@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.about-us-second-sec-container {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    flex-wrap: wrap;
    /* transform: translate(-50%, -50%); */
    display: flex;
    justify-content: center;
}

.about-us-box-content{
    padding-top: 50px;
    padding-bottom: 50px;
    width: 60%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 20px ;
    background-color: rgb(16, 82, 161);
    margin-bottom: 10%;
}



.about-us-box-content h1{
    width: 70%;
    font-family: 'Barlow-Regular';
    font-size: 30px;
    text-align: center;
    align-self: center;    
    color:azure;
}

.about-us-box-content p{
    width: 70%;
    font-weight: bold;
    font-family: 'Barlow-thin';
    font-size: 18px;
    align-self: center;
    text-align: center;
    color:azure;
}

.about-us-box-content2{
    padding-top: 50px;
    padding-bottom: 50px;
    width: 60%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 20px ;
    background-color: rgb(16, 82, 161);
    margin-bottom: 10%;
}
.about-us{
    position: absolute;
    top: 0;
    padding-top: 63.87px;
    width: 100%;
}


.about-us-box-content2 h1{
    width: 70%;
    font-family: 'Barlow-Regular';
    font-size: 30px;
    text-align: center;
    align-self: center;    
    color:azure;

}

.about-us-box-content2 p{
    width: 70%;
    font-weight: bold;
    font-family: 'Barlow-thin';
    font-size: 18px;
    align-self: center;
    text-align: justify;
    color:azure;
}

@media screen and (max-width: 390px) {
    .about-us-box-content{
        width: 200px;
        padding: 0;
        height: 180px;
    }
  
    .about-us-box-content h1{
        font-size: 14px;
    }
    .ceo-section h1 {
        font-size: 20px;
    }
    .ceo-section p {
        font-size: 16px;
    }
    .about-us-box-content p{
        width: 70%;
        font-size: 8px;
    }

    .about-us-box-content2 h1{
        font-size: 14px;
    }
    
    .about-us-box-content2 p{
        width: 70%;
        font-size: 10px;
    }
    .about-us-box-content p {
        font-size: 10px;
    }
}