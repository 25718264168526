.read-or-hide{
    color: rgb(192,192,192);
    cursor: pointer;
  }

.ag-container{
    text-align: center;
    flex-direction: row;
    align-content: space-evenly;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}
.ag-sub-container img{
    object-fit: contain;   
}

.ag-box-container {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 45%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
    border-radius: 20px;
    background-color: rgb(16, 82, 161);
    margin-bottom: 10%;
    margin-top: 10%;
}

.ag-alone-container img{
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: contain;
    width: 90%;
}
.ag-alone-container p{
    margin-top: 2%;
    font-size: 20px;
    width: 90%;
    text-align: justify;
    font-family: 'Barlow';
    font-style: 'semi-condense-bold';
}

.ag-alone-container{
    text-indent: 50px;
    display: flex;
    flex-direction: column;
    height: auto;
    position: relative;
    justify-content: center;
    align-items: center;
}

.ag-sub-container p{
    font-size: 20px;
    font-family: 'Barlow';
    font-style: 'semi-condense-bold';
}
.ag-sub-container{
    display: flex;
    flex-direction: column;
    width: 47%;
    height: auto;
    position: relative;
    justify-content: center;
}


@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.ag-container video{
    position: relative;
    display: flex;
    width: 90%;
    height: auto;
}

.ag-sub-container h1 {
    font-weight: 800;
    position: relative;
    font-family: 'Barlow';
    font-size: 40px;
    align-self: center;
    color: #00469c;
}

.space-between{
    height: 20px;
}

.hotel-logo{
    display: flex;
    flex-direction: column;
    height: auto;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 47%;
}

.dr-matin-img{
    display: flex;
    flex-direction: column;
    width: 42%;
    padding-left: 4%;
    position: relative;
    justify-content: center;
}


@media screen and (max-width: 414px) {
    .ag-sub-container p{
        font-size: 15px;
    }

    .ag-alone-container p{
        font-size: 15px;
    }
    .dr-matin-img img{
        width: auto;
        object-fit: contain;
    }
    .dr-matin-img {
        width: 70%;
        padding: 0%;

    }

    
    .hotel-logo img{
        width: 50%;
    }

}

@media screen and (max-width: 768px) {

    .dr-matin-img img{
        width: auto;
        object-fit: contain;
    }
    .dr-matin-img {
        width: 85%;
        padding: 0%;

    }


    .ag-sub-container p{
        font-size: 18px;
    }

    .ag-alone-container p{
        font-size: 18px;
    }
    .hotel-logo img{
        width: 70%;
    }
    .ag-sub-container{
        width: 90%;
    }
}